<template>
  <div class="news">
    <div class="section-vision"></div>
    <div class="section-article row-center">
      <div class="section-box article">
        <div class="article-head">
          <div class="icon"></div>
          <h1 class="title">
            <strong>新闻资讯</strong>
          </h1>
        </div>
        <div class="article-body">
          <a :href="item.link" class="item" v-for="(item, index) in news" :key="index">
            <div class="item-head">
              <img :src="item.poster" alt="" />
            </div>
            <div class="item-body">
              <strong>{{ item.title }}</strong>
              <em>{{ item.time }}</em>
              <span>{{ item.summary }}</span>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ARTICLE_LIST } from '@/utils/const.js';

export default {
  name: 'News',
  data() {
    return {
      news: ARTICLE_LIST,
    };
  },
};
</script>

<style lang="scss" scoped>
@media screen and (max-width: 750px) {
  .news {
    background: #fff;
    .section-vision {
      background: url("../../assets/news/vision-m.jpg") no-repeat center center;
      background-size: cover;
      width: 100%;
      height: 4rem;
    }
    .section-article {
      background: #fff;
      padding: 0.6rem;
      box-sizing: border-box;
      .article {
        display: flex;
        flex-direction: column;
        &-head {
          .icon {
            display: none;
          }
          .title {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: baseline;
            margin: 0;
            & > strong {
              font-family: PingFangSC-Medium;
              font-size: 0.36rem;
              color: #e76d01;
              line-height: 0.45rem;
            }
          }
        }
        &-body {
          margin-top: 0.3rem;
          display: flex;
          flex-direction: column;
          .item {
            width: 100%;
            display: flex;
            justify-content: space-between;
            padding: 0.24rem 0;
            box-sizing: border-box;
            &:not(:last-child) {
              border-bottom: 0.02rem solid rgba(230, 229, 229, 0.17);
            }
            &-head {
              flex: 0 0 auto;
              width: 2rem;
              height: 1.8rem;
              background: #d8d8d8;
              border-radius: 0.06rem;
              overflow: hidden;
              margin-right: 0.16rem;
              & > img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }
            &-body {
              display: flex;
              flex-direction: column;
              overflow: hidden;
              & > strong {
                font-size: 0.32rem;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #e76d00;
                line-height: 0.36rem;
                margin-bottom: 0.1rem;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                text-align: left;
              }
              & > em {
                font-size: 0.26rem;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                line-height: 0.36rem;
                margin-bottom: 0.25rem;
                text-align: left;
                font-style: normal;
              }
              & > span {
                font-size: 0.26rem;
                font-family: PingFangSC-Light, PingFang SC;
                font-weight: 300;
                color: #333333;
                line-height: 0.4rem;
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-line-clamp: 2;
                word-break: break-all;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                text-align: left;
              }
            }
            &:first-child {
              flex-direction: column;
              .item-head {
                width: 100%;
                height: 2.64rem;
                margin: 0 0 0.24rem 0;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (min-width: 750px) {
  .news {
    background: #fff;
    .section-vision {
      background: url("../../assets/news/vision.jpg") no-repeat center center;
      background-size: cover;
      min-width: 1190px;
      height: 500px;
    }
    .section-article {
      background: #fff;
      padding: 150px 0 110px;
      box-sizing: border-box;
      .article {
        display: flex;
        flex-direction: column;
        &-head {
          padding-top: 13px;
          box-sizing: border-box;
          .icon {
            background: url("../../assets/news/news0.png") no-repeat center;
            background-size: 100%;
            width: 401px;
            height: 45px;
            margin-bottom: 10px;
          }
          .title {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: baseline;
            margin: 0;
            & > strong {
              font-family: PingFangSC-Medium;
              font-size: 32px;
              color: #e76d01;
              line-height: 32px;
            }
          }
        }
        &-body {
          margin-top: 60px;
          display: flex;
          flex-direction: column;
          .item {
            width: 100%;
            display: flex;
            justify-content: space-between;
            margin-bottom: 56px;
            &-head {
              flex: 0 0 auto;
              width: 445px;
              height: 270px;
              background: #d8d8d8;
              border-radius: 6px;
              overflow: hidden;
              margin-right: 60px;
              & > img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }
            &-body {
              display: flex;
              flex-direction: column;
              overflow: hidden;
              & > strong {
                font-size: 32px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #e76d00;
                line-height: 36px;
                margin-bottom: 20px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                text-align: left;
              }
              & > em {
                font-size: 24px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                line-height: 33px;
                margin-bottom: 20px;
                text-align: left;
                font-style: normal;
              }
              & > span {
                font-size: 20px;
                font-family: PingFangSC-Light, PingFang SC;
                font-weight: 300;
                color: #333333;
                line-height: 40px;
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-line-clamp: 4;
                word-break: break-all;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                text-align: left;
              }
            }
            &:first-child {
              flex-direction: column;
              .item-head {
                width: 100%;
                height: 500px;
                margin: 0 0 80px 0;
              }
            }
          }
        }
      }
    }
  }
}
</style>
